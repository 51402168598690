<template>
    <div>
        <va-card :title="title">
            <div>
                <form>
                    <span class="va-form-label va-form-required-label">Home Welcome Message</span>
                    <va-input 
                        v-model="home_welcome" 
                        type="text" 
                        placeholder="Enter Welcome Message" 
                        :error="!!WelcomeMsgError.length"
                        :error-messages="WelcomeMsgError" 
                    />
                    <span class="va-form-label va-form-required-label">Home Introduction Message</span>
                    <va-input 
                        v-model="home_intro" 
                        type="text" 
                        placeholder="Enter Introduction Message" 
                        :error="!!IntroductionMessageError.length"
                        :error-messages="IntroductionMessageError" 
                    />
                    <span class="va-form-label va-form-required-label">About Food</span>
                    <va-input 
                        v-model="food_intro" 
                        type="text" 
                        placeholder="Enter About Food" 
                        :error="!!AboutCodeError.length"
                        :error-messages="AboutCodeError" 
                    />
                    <span class="va-form-label va-form-required-label">Food Video</span><br />
                    <input 
                        type="file" 
                        style="display: none;" 
                        ref="food_video" 
                        accept=".mp4" 
                        id="food_video"
                        class="mt-2" 
                        v-on:change="validateVideo($event, 'food')" 
                    />
                    <va-button 
                        type="submit" 
                        class="mt-2 ml-1" 
                        @click.prevent="$refs.food_video.click()">
                        Upload Video
                    </va-button>
                    <label class="ml-4">{{ foodVideoName }}</label><br>
                    <span class="va-form-label va-form-required-label">Home Video</span><br/>
                    <input 
                        type="file" 
                        style="display: none;" 
                        ref="home_video" 
                        accept=".mp4" 
                        id="home_video"
                        class="mt-2" 
                        v-on:change="validateVideo($event, 'home')" 
                    />
                    <va-button 
                        type="submit" 
                        class="mt-2 ml-1" 
                        @click.prevent="$refs.home_video.click()">
                        Upload Video
                    </va-button>
                    <label class="ml-4">{{ homeVideoName }}</label>
                    <div class="d-flex justify--end mt-3">
                        <va-button 
                            v-if="!isUpdate" 
                            type="submit" 
                            class="my-0" 
                            @click.prevent="HepiSettings('create')">
                            Create
                        </va-button>
                        <va-button 
                            v-if="isUpdate" 
                            type="submit" 
                            class="my-0" 
                            @click.prevent="HepiSettings('update')">
                            Update
                        </va-button>
                    </div>
                </form>
            </div>
        </va-card>
    </div>
</template>
<script>
    import Vue from 'vue'
    import vueResource from 'vue-resource'
    import config from '../../i18n/en.json'
    Vue.use(vueResource)
    export default {
        name: 'hepi_setting',
        data() {
            return {
                home_welcome: '',
                home_intro: '',
                food_intro: '',
                WelcomeMsgError: [],
                IntroductionMessageError: [],
                AboutCodeError: [],
                title: '',
                food_video: '',
                home_video: '',
                foodVideoName: '',
                homeVideoName: '',
                isUpdate: false,
            }
        },
        computed: {
            formReady () {
                return !this.WelcomeMsgError.length && 
                       !this.IntroductionMessageError.length && 
                       !this.AboutCodeError.length
            }
        },
        created() {
            this.getHepiSettings();
        },
        methods: {
            getHepiSettings() {
                this.$http.get(config.menu.host + '/hepi_setting').then(response => {
                    if (response.body == '') {
                        this.reset()
                    } else {
                        this.edit(response.body)
                    }
                })
            },
            validateVideo(event, type) {
                var video = event.target.files[0]
                var size = event.target.size
                if ((size / (1024 * 1024)) <= 200) {
                    if(type == 'food'){
                        this.foodVideoName = video.name
                        this.food_video = video    
                        return true
                    }else{
                        this.homeVideoName = video.name
                        this.home_video = video
                        return true
                    }
                } else {
                    alert('please upload video less than 200 MB')
                    if (type == 'food') this.foodVideoName = '';
                    if (type == 'home') this.homeVideoName = '';
                    return false
                }
            },
            HepiSettings(type) {
                this.WelcomeMsgError = this.home_welcome ? [] : ['Welcome message is required']
                this.IntroductionMessageError = this.home_intro ? [] : ['Introduction message is required']
                this.AboutCodeError = this.food_intro ? [] : ['Food details is required']

                if((this.home_video.length == 0 || this.food_video.length == 0) && !this.isUpdate){
                    return Vue.notify({text:"Please upload video",type:'error'})
                }

                var payload = new FormData()
                payload.append('home_welcome', this.home_welcome);
                payload.append('home_intro', this.home_intro);
                payload.append('food_intro', this.food_intro);
                if (this.food_video.length != 0) payload.append('food_video', this.food_video);
                if (this.home_video.length != 0) payload.append('home_video', this.home_video);
                payload.append('homeVideoName', this.homeVideoName);
                payload.append('foodVideoName', this.foodVideoName);

                if(type == 'create'){
                    var requestUrl = this.$http.post(config.menu.host + '/hepi_setting', payload)
                }else{
                    var requestUrl = this.$http.put(config.menu.host + '/hepi_setting/' + this.hepi_setting_id, payload)
                }

                const loader = Vue.$loading.show({ width: 40, height: 40 })
                requestUrl.then(responseData => {
                    loader.hide()
                    Vue.notify({ text: responseData.body, type: 'success' })
                    this.getHepiSettings()
                }, err => {
                    loader.hide()
                    if (err && err.body) {
                        Vue.notify({ text: err.body, type: 'error' })
                        this.getHepiSettings()
                    };
                })
            },
            reset() {
                this.isUpdate = false;
                this.title = 'Create Hepi Setting'
                this.WelcomeMsgError = [];
                this.IntroductionMessageError = [];
                this.AboutCodeError = [];
                this.home_welcome = '';
                this.home_intro = '';
                this.food_intro = '';
                this.foodVideoName = '';
                this.homeVideoName = '';
                this.food_video = '';
                this.home_video = '';
            },
            edit(row) {
                this.isUpdate = true;
                this.title = 'Update Hepi Setting';
                this.home_welcome = row.home_welcome;
                this.home_intro = row.home_intro;
                this.food_intro = row.food_intro;
                this.foodVideoName = row.foodVideoName;
                this.homeVideoName = row.homeVideoName;
                this.hepi_setting_id = row.hepi_setting_id;
                this.home_video = '';
                this.food_video = '';
                this.WelcomeMsgError = [];
                this.IntroductionMessageError = [];
                this.AboutCodeError = [];
            },
        },
    }
</script>
<style scoped>
</style>
